<template>
  <div class="project-youtube-container">
    <iframe :src="`https://www.youtube.com/embed/${this.itemData.content}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <!-- <figcaption v-if="itemData.sub.length > 1" class="image-sub-container">
      <figcaption class="image-sub">{{itemData.sub}}</figcaption>
    </figcaption> -->
  </div>
</template>

<script>
export default {
  name: 'ProjectVideo',
  props: {
    slug: String,
    itemData: Object,
  },
  methods: {
  },
  computed: {
  },
  mounted: function () {
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';

  .project-youtube-container{
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  
  .image-sub-container {
    width: 100%;
    display: block;
    text-align: right;
  }
  .image-sub {
    // background-color: $c-lime;
    text-align: right;
    display: inline-block;
    padding: 0.4em 0.7em 0.7em 0.4em;
    margin-top: 4px;
    // color: $c-grey;
    font-size: 0.7em;
    background-color: #eeeeee;
    color: black;
    width: 100%;
    // max-width: 60%;
    // margin-right: 5%;
  }

</style>