<template>
<div>
  <div class="project-image-container" :class="{'isDev': this.employerSignifier}">

    <img
      v-show="!loaded"
      class="content-image-lowres"
      :data-src="`/static/images/${this.employerFolder}${slug}/LR/${itemData.content}`"
    />
    <picture v-show="loaded" class="content-image">
      <source :srcset="`/static/images/${this.employerFolder}${slug}/${itemData.content}.webp`" type="image/webp">
      <source :srcset="`/static/images/${this.employerFolder}${slug}/${itemData.content}.jpg`" type="image/jpeg">
      <img    :src="`/static/images/${this.employerFolder}${slug}/${itemData.content}.jpg`"/>
      <!-- <img :id="`image-thumb-${projectId}`" :src="`./static/images/${this.employerSignifier}${this.projectData.slug}/${this.projectData.thumb.filename}`"/> -->
    </picture>
    <!-- <img
      v-show="loaded"
      class="content-image"
      :data-src="`/static/images/${this.employerFolder}${slug}/${itemData.content}`"
    /> -->
  </div>
  <figcaption v-if="itemData.type == 'img' && itemData.sub.length > 1" class="image-sub-container">
    <figcaption v-if="itemData.type == 'img'" class="image-sub" :class="{'isDev': this.employerSignifier}" >{{itemData.sub}}</figcaption>
  </figcaption>
</div>
</template>

<script>
export default {
  name: 'ProjectImage',
  data: function () {
    return {
      loaded: false,
    }
  },
  props: {
    slug: String,
    itemData: Object,
    employerSignifier : String
  },
  methods: {
  },
  mounted: function () {
    const container = this.$el.getElementsByClassName('project-image-container')[0]
    const paddingAmount = this.itemData.dimensions.y / this.itemData.dimensions.x * 100
    container.style.paddingBottom = `${paddingAmount}%`
    // console.log(paddingAmount)
    const image = this.$el.getElementsByClassName('content-image')[0].getElementsByTagName('img')[0]
    // const lrImage = this.$el.getElementsByClassName('content-image-lowres')[0]
    // lrImage.addEventListener('load', () => {
    //   image.src = image.dataset.src
    //   this.loaded = true
    //   image.style.minHeight = 0;
    // })
    image.addEventListener('load', () => {
      this.loaded = true
    })
  },
  computed: {
    employerFolder () {
      if (this.employerSignifier != null) {
        return this.employerSignifier + '/'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';

  .project-image-container {
    overflow: hidden;
    position: relative;
    // background-color: lightgrey;
    line-height: 0;
    box-shadow: $c-lime 4px 4px 0 4px;
    &.isDev {
      box-shadow: $c-yellow 4px 4px 0 4px;
    }
    & img {
      width: 100%;
    }
  }

  .content-image {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: auto 0;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
  }
  .content-image-lowres {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: 1em auto 0;
    text-align: center;
    min-height: 200px;
    filter: blur(20px);
    transform: scale(1.12);
    position: absolute;
    left: 0;
    top: 0;
  }

  .image-sub-container {
    width: 100%;
    display: block;
    text-align: right;
  }

  .image-sub {
    text-align: right;
    display: inline-block;
    padding: 0.4em 0.7em 0.7em 0.4em;
    margin-top: 4px;
    // color: $c-grey;
    font-size: 0.7em;
    background-color: #eeeeee;
    background-color: $c-lime;
    color: black;
    width: calc(100% + 8px);
    margin-top: 13px;
    // max-width: 60%;
    // margin-right: 5%;
    &.isDev {
      background-color: $c-yellow;
    }
  }

  .hasImage::before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    background-color: $c-lime;
    margin: 0 auto 0.8em;
    border-radius: 50%;
  }

  @media (min-width: 1080px) {
  .project-image-container {
    box-shadow: $c-lime 5px 5px 0 5px;
  }
  .image-sub {
    width: calc(100% + 10px);
    margin-top: 18px;
  }
}
</style>