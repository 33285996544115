<template>
  <div class="project-page color-bg">
    <title-bar/>
    <div class="project-details-container">
      <dot-label :isTitle="true" :text="this.projectData.name" :color="this.projectData.category"/>
      <div class="project-sub">{{projectDetails.sub}}</div>

      <div v-if="this.projectData.employer != null" class="employer-container">
        <span class="employer-item employer">
          <div class="employer-label">
            Employer
          </div>
          <div class="employer-content">
            {{this.projectDetails.employer}}
          </div>
        </span>
        <span class="employer-item client">
          <div class="employer-label">
            Client
          </div>
          <div class="employer-content">
            {{this.projectDetails.client}}
          </div>
        </span>
        <span class="employer-item role">
          <div class="employer-label">
            Role
          </div>
          <div class="employer-content">
            {{this.projectDetails.role}}
          </div>
        </span>
      </div>

      <content>
        <div class="content-container">
          <div class="content-item" :class="{'hasImage': item.type == 'img'}" v-for="item in projectDetails.contentList" v-bind:key="item.order">
            <project-text  :slug="projectData.slug" :item-data="item" v-if="item.type == 'text'" />
            <project-video :slug="projectData.slug" :item-data="item" v-if="item.type == 'video'" />
            <project-youtube :slug="projectData.slug" :item-data="item" v-if="item.type == 'youtube'" />
            <project-image :slug="projectData.slug" :item-data="item" :employerSignifier="projectData.employer" v-if="item.type == 'img'" />
            <project-gif :slug="projectData.slug" :item-data="item" v-if="item.type == 'gif'" />
            <project-audio :slug="projectData.slug" :item-data="item" v-if="item.type == 'audio'" />
          </div>
        </div>
      </content>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import ProjectAttract from '@/components/ProjectAttract.vue'
// import projectDetailsImport from '../model/projectDetailsImport';
import TitleBar from '@/components/TitleBar.vue'
import DotLabel from '../components/DotLabel.vue';
import ProjectVideo from '../components/projectContentTypes/ProjectVideo.vue'
import ProjectYoutube from '../components/projectContentTypes/ProjectYoutube.vue'
import ProjectImage from '../components/projectContentTypes/ProjectImage.vue'
import ProjectGif from '../components/projectContentTypes/ProjectGif.vue'
import ProjectAudio from '../components/projectContentTypes/ProjectAudio.vue'
import dataImport from '../model/dataImport'
import ProjectText from '../components/projectContentTypes/ProjectText.vue';
// import OverviewProject from '../components/OverviewProject.vue'
// import animejs from 'animejs'


export default {
  name: 'Project',
  components: {
    // ProjectAttract
    TitleBar,
    DotLabel,
    ProjectVideo,
    ProjectYoutube,
    ProjectImage,
    ProjectGif,
    ProjectText,
    ProjectAudio
    // OverviewProject
  },
  data: function () {
    return {
      projectData: dataImport.getBySlug(this.$route.params.projectSlug),
      // projectDetails: {},
      // projectDetails: require('../../public/data/thumbs-against-missing-out.json')
      projectDetails: null
    }
  },
  methods: {
    loadData: async function () {
      // dataImport.getBySlug(this.$route.params.projectSlug)
      // console.log(this.$route)
      // dataImport.get(this.$route.params.id)
      // this.projectDetails = await projectDetailsImport.load(this.$route.params.projectSlug)
    }
  },
  beforeMount: function () {
    // console.log(this.projectData)
    this.loadData()
  },
  created: function () {
    document.title = `Cas Spoelstra - ${this.projectData.name}`
    if (this.projectData.employer === 'SL') {
      this.projectDetails = require(`../../public/data/SL/${this.$route.params.projectSlug}.json`)
    } else {
      this.projectDetails = require(`../../public/data/${this.$route.params.projectSlug}.json`)
    }
    // console.log(test)
  }
}
</script>

<style scoped lang="scss">
@import '@/style/global.scss';
.project-page {
  padding-bottom: 6em;
  // background-color: #eeeeee;
}

// .color-interactionDesign {
//   background-color: $c-yellow;
// }

.project-details-container {
  padding-top: 3em;
  background-color: $c-bg;
  max-width: 1080px;
  margin: 0 auto;
}


.dot-label.title {
  // padding-bottom: 0.5em;
  // background-color: $c-lime;
  width: 80%;
  max-width: 100%;
}

div.dot-text-actual {
  color: $c-lime;
}

.project-sub {
  color: grey;
  text-align: center;
  padding-bottom: 2em;
  width: 80%;
  margin: 0 auto;
  // background-color: $c-lime;
}

.content-container {
  padding: 0 5% 0;
}

.content-item {
  margin: 3.5em auto;
}
.employer-container {
  margin: 1.5em auto 5em;
  text-align: center;
}

.employer-item {
  display: inline-block;
  margin: 0.5em 0 0 0;
  text-align: left;
  &.employer {
    margin-right: 0.5em;
  }
  &.client {
    margin-right: 0.5em;
  }
  // &.role {
  //   margin-left: 0.25em;
  // }
}

.employer-label {
  color: $c-grey;
  font-size: 0.8em;
  margin-bottom: 0.2em;
}

.employer-content {
  background-color: $c-yellow;
  padding: 0.3em;
}

@media (prefers-color-scheme: dark) {
  .project-details-container {
    background-color: $c-d-bg;
  }

  content {
    color: $c-d-text;
  }

  .employer-content {
    background-color: $c-d-yellow;
  }
}

</style>
