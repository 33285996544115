<template>
  <div class="dot-label" :class="{ title: isTitle }">
    <div class="default-color" v-bind:class="'dot-behind' + ' ' + `color-${color}`"></div>
    <div class="dot-text">{{text}}</div>
    <div class="dot-text-actual">{{text}}</div>
  </div>
</template>

<script>
import animejs from 'animejs'

const dotGrowFactor = 1.6

export default {
  name: 'DotLabel',
  data: function () {
    return {
      dotEl: null,
      animation: null
    }
  },
  props: {
    color: String,
    text: String,
    isTitle: Boolean
  },
  methods: {
    setDotPosition: function () {
      // this.dotEl.style.marginTop = (this.heightOfTextLabel / 2) + 3 + 'px'
      // this.dotEl.style.height = this.heightOfTextLabel  * dotGrowFactor + 'px'
      // this.dotEl.style.width = this.heightOfTextLabel  * dotGrowFactor + 'px'
    },
    shrinkOnHover: function () {
      this.animation?.pause()
      animejs.remove(this.dotEl)
      this.animation = animejs({
        targets: this.dotEl,
        height: (this.heightOfTextLabel  * dotGrowFactor) - 5 + 'px',
        width: (this.heightOfTextLabel  * dotGrowFactor) - 5 + 'px',
        time: 700
      })
    },
    growOnHoverExit: function () {
      this.animation?.pause()
      animejs.remove(this.dotEl)
      this.animation = animejs({
        targets: this.dotEl,
        height: this.heightOfTextLabel  * dotGrowFactor + 'px',
        width: this.heightOfTextLabel  * dotGrowFactor + 'px',
        time: 700
      })
    },
    takeOverScreen: function () {
      this.animation?.pause()
      animejs.remove(this.dotEl)
      animejs({
        targets: this.dotEl,
        height: this.heightOfTextLabel  * 20 + 'px',
        width: this.heightOfTextLabel  * 20 + 'px',
        time: 700
      })
    }
  },
  computed: {
    heightOfTextLabel: function () {
      return this.$el.querySelector('.dot-text').clientHeight
    }
  },
  mounted: function () {
    this.dotEl = this.$el.getElementsByClassName('dot-behind')[0]
    // this.setDotPosition()
    // !! Interactivity maybe
    // this.$el.addEventListener('mouseover', () => this.shrinkOnHover())
    // this.$el.addEventListener('mouseleave', () => this.growOnHoverExit())
    // this.$el.addEventListener('click', () => this.takeOverScreen())
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';

  .dot-label {
    display: inline-block;
    margin: 0.3em 0.5em 0;
    max-width: 45%;
    vertical-align: top;
    position: relative;
  }
  .dot-label.title {
    display: block;
    font-size: 2em;
    font-weight: 700;
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
  }

  .dot-behind {
    position: relative;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    // margin-top: -10px;
    transform: translate(-25px,-31px);
    // transform: translate(-50%,-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 1;
    z-index: 0;
  }

  .dot-behind::before {
  }

  .dot-text {
    text-align: center;
    opacity: 0;
    padding-bottom: 0.5em;
  }
  .dot-text-actual {
    color: black;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  @media (prefers-color-scheme: dark) {
    .dot-text-actual {
      color: $c-d-text;
    }
  }


</style>