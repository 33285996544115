<template>
  <p class="content-text" v-if="itemData.type == 'text'" v-html="itemData.content"></p>
</template>

<script>
export default {
  name: 'ProjectText',
  props: {
    slug: String,
    itemData: Object,
  },
  methods: {
  },
  computed: {
  },
  mounted: function () {
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';

  .content-text {
    display: block;
    width: 100%;
    margin: 0 auto;
    white-space: pre-wrap; 
    word-wrap: break-word;
    font-family: inherit;
    line-height: 1.3em;
  }

</style>