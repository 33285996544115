<template>
  <div class="project-video-container">
    <video width="100%" height="auto" :poster="`/static/images/${slug}/${itemData.poster}`" loop="true" controls>
      <source :src="`/static/video/${slug}/${itemData.content}.webm`" type="video/webm">
      <source :src="`/static/video/${slug}/${itemData.content}.mp4`" type="video/mp4">
    </video>
    <figcaption v-if="itemData.sub.length > 1" class="image-sub-container">
      <figcaption class="image-sub">{{itemData.sub}}</figcaption>
    </figcaption>
  </div>
</template>

<script>
export default {
  name: 'ProjectVideo',
  props: {
    slug: String,
    itemData: Object,
  },
  methods: {
  },
  computed: {
  },
  mounted: function () {
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';
  
  .image-sub-container {
    width: 100%;
    display: block;
    text-align: right;
  }
  .image-sub {
    // background-color: $c-lime;
    text-align: right;
    display: inline-block;
    padding: 0.4em 0.7em 0.7em 0.4em;
    margin-top: 4px;
    // color: $c-grey;
    font-size: 0.7em;
    background-color: #eeeeee;
    color: black;
    width: 100%;
    // max-width: 60%;
    // margin-right: 5%;
  }

</style>