<template>
<div>
  <div class="project-image-container">
    <img
      class="content-image"
      :src="`/static/images/${slug}/${itemData.content}`"
    />
  </div>
  <figcaption v-if="itemData.type == 'img' && itemData.sub.length > 1" class="image-sub-container">
    <figcaption v-if="itemData.type == 'img'" class="image-sub">{{itemData.sub}}</figcaption>
  </figcaption>
</div>
</template>

<script>
export default {
  name: 'ProjectGif',
  data: function () {
    return {
      loaded: false
    }
  },
  props: {
    slug: String,
    itemData: Object,
  },
  methods: {
  },
  computed: {
  },
  mounted: function () {
    const image = this.$el.getElementsByClassName('content-image')[0]
    image.addEventListener('load', () => {
      image.style.minHeight = 0;
      this.loaded = true
    })
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';

  .project-image-container {
    overflow: hidden;
  }

  .content-image {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: 1em auto 0;
    text-align: center;
    min-height: 400px;
    background-color: lightgrey;
  }
  .content-image-lowres {
    display: block;
    width: 100%;
    object-fit: contain;
    margin: 1em auto 0;
    text-align: center;
    min-height: 200px;
    filter: blur(20px);
    transform: scale(1.12)
  }

  .image-sub-container {
    width: 100%;
    display: block;
    text-align: right;
  }

  .image-sub {
    // background-color: $c-lime;
    text-align: right;
    display: inline-block;
    padding: 0.4em 0.7em 0.7em 0.4em;
    margin-top: 4px;
    // color: $c-grey;
    font-size: 0.7em;
    background-color: #eeeeee;
    color: black;
    width: 100%;
    // max-width: 60%;
    // margin-right: 5%;
  }

  .hasImage::before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    background-color: $c-lime;
    margin: 0 auto 0.8em;
    border-radius: 50%;
  }
</style>