<template>
  <div class="project-audio-container">
    <iframe
      width="100%"
      height="auto"
      scrolling="no"
      frameborder="no"
      :src="`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${itemData.content}&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_artwork=false&amp;color=000000&amp;theme_color=ffffff&amp;`" />
  </div>
</template>

<script>
export default {
  name: 'ProjectAudio',
  props: {
    slug: String,
    itemData: Object,
  },
  methods: {
  },
  computed: {
  },
  mounted: function () {
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';
  
  .image-sub-container {
    width: 100%;
    display: block;
    text-align: right;
  }
  .image-sub {
    // background-color: $c-lime;
    text-align: right;
    display: inline-block;
    padding: 0.4em 0.7em 0.7em 0.4em;
    margin-top: 4px;
    // color: $c-grey;
    font-size: 0.7em;
    background-color: #eeeeee;
    color: black;
    width: 100%;
    // max-width: 60%;
    // margin-right: 5%;
  }

</style>