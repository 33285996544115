// import * as projects from '../data/projects.json'
import p1 from '../data/project1.json'
import p2 from '../data/project2.json'
import p3 from '../data/project3.json'
import p4 from '../data/project4.json'
import p5 from '../data/project5.json'
import p6 from '../data/project6.json'
import p7 from '../data/project7.json'
import p8 from '../data/project8.json'
import p9 from '../data/project9.json'
import p10 from '../data/project10.json'
import p11 from '../data/project11.json'

import sl_p01 from '../data/SL/project1.json'
import sl_p02 from '../data/SL/project2.json'
import sl_p03 from '../data/SL/project3.json'
import sl_p04 from '../data/SL/project4.json'

class DataImport {
  constructor() {
    this.data = { list: [] }
    this.load()
    this.sort()
  }

  load () {
    const projectsList = []
    projectsList.push(p1)
    projectsList.push(p2)
    projectsList.push(p3)
    projectsList.push(p4)
    projectsList.push(p5)
    projectsList.push(p6)
    projectsList.push(p7)
    projectsList.push(p8)
    projectsList.push(p9)
    projectsList.push(p10)
    projectsList.push(p11)
    projectsList.push(sl_p01)
    projectsList.push(sl_p02)
    projectsList.push(sl_p03)
    projectsList.push(sl_p04)
    this.data.list = projectsList
  }

  sort () {
    console.log(this.data.list[0])
    this.data.list.sort(function (a, b) {
      return parseFloat(a.order) - parseFloat(b.order)
    })
    console.log(this.data.list[0])
    // this.data.list.sort(sortAlgorithm)
  }

  get (id) {
    return this.data.list[id]
  }

  getBySlug (slug = null) {
    let elementToReturn = null
    this.data.list.forEach(element => {
      if (element.slug === slug) elementToReturn = element
    });
    if (elementToReturn != null) {
      return elementToReturn
    } else {
      return new Error(`Couldn't find the item`)
    }

  }
}
const dataImport = new DataImport()
export default dataImport
